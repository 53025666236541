html {
    font-size: 62.5%;}

input:-webkit-autofill, input:focus:-webkit-autofill {
   -webkit-box-shadow: 0 0 0 100px rgb(255, 255, 255) inset;}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;}

a {
    text-decoration: none;
    color: inherit;}

#lookElse {
    border-bottom: 2px solid blue;
    cursor: pointer;}

#container {
    width: 100vw;
    max-height: 100vh;
    overflow-y: scroll;
    height: calc(var(--vh, 1vh) * 100);
    scroll-snap-type: mandatory;
    scroll-snap-type: y mandatory;}
    
#topBlock, #bottomBlock {
    position: relative;
    scroll-snap-align: center;
    width: 100%;
    height: 100%;}
    
#topText, #bottomText {
    width: 100%;
    height: auto;
    padding: 1.5%;
    font-size: 2.5rem;}
    
.leftColumn {
    font-family: sans-serif;}
    
.rightColumn {
    font-family: serif !important;}
    
#topText p, #bottomText p {
    position: relative;
    width: 50%;
    display: inline-block;
    vertical-align: top;}

#shelf {
    position: absolute;
    bottom: 0 !important;
    min-width: 100%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    font-size: 0;}
.bookItem {
    position: relative;
    bottom: 0;
    height: 65vh;
    width: 20%;
    display: inline-block;
    overflow: hidden;}
.bookItem p {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%,-50%) rotate(90deg);
    font-size: 1.5rem !important;
    font-family: sans-serif;}

.plus-one {
    transform: rotate(1deg);}

.minus-one {
    transform: rotate(-1deg);}

.plus-two {
    transform: rotate(2deg);}

.minus-two {
    transform: rotate(-2deg);}

#formContainer {
    position: relative;
    left: 50%;
    width: 50%;
    overflow-x: hidden;
    margin-top: 5%;}

#newArrivals, #newArrivals:active {
    width: 100%;
    background: none !important;
    color: inherit;
    border: none;
    border-bottom: 2px solid blue;
    padding: 0;
    font: inherit;
    cursor: text;
    outline: inherit;
    border-radius: 0;}

#flowArrivals {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
    font: inherit;
	cursor: pointer;
    outline: inherit;
    margin-top: 1rem;}

input:-webkit-autofill {
    background-color: #FAFFBD !important;}

#credits {
    position: absolute;
    color: grey;
    font-family: sans-serif;
    bottom: 0;
    left: 0;
    padding: 1.5%;
    font-size: 1.75rem;}

#credits a {
    border-bottom: 2px solid blue;}

@media only screen and (orientation:landscape) {
    .bookItem {
        height: 80vh;
        width: 7.5%}
}